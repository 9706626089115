import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { Device, useCreateDevice } from '../../../hooks/useCreateDevice';
import { useDialog } from '../../../hooks/useDialog';
import { useDeliveryBoxAuthPassword } from '../../../hooks/useGetDeliveryBoxAuthPassword';
import { useInterval } from '../../../hooks/useInterval';
import { useListDevice } from '../../../hooks/useListDevice';
import FormRadioGroup from '../../molecules/FormRadioGroup';
import FormTextField from '../../molecules/FormTextField';
import MessageBox from '../../molecules/MessageBox';
import DeliveryBoxAddDialog from '../../organisms/DeliveryBoxAddDialog';
import DeliveryBoxAuthPasswordDialog from '../../organisms/DeliveryBoxAuthPasswordDialog';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    bold: {
      fontWeight: 'bold',
    },
    secondaryText: {
      display: 'block',
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  }),
);

const validPasswordLength = (password?: number) =>
  password !== undefined && String(password).length <= 6;
const validPasswordRange = (password?: number) =>
  password !== undefined && /^[1-4]*?$/.test(String(password));

const validationSchema = Yup.object().shape({
  yourPassword: Yup.number()
    .typeError('数字で入力して下さい')
    .required('必須項目です')
    .test('maxLength', '6桁以内で入力して下さい', (yourPassword) =>
      validPasswordLength(yourPassword),
    )
    .test(
      'numberRanges',
      '1-4の数字の組み合わせで入力して下さい',
      (yourPassword) => validPasswordRange(yourPassword),
    ),
  deliveryPassword: Yup.number()
    .typeError('数字で入力して下さい')
    .required('必須項目です')
    .test('maxLength', '6桁以内で入力して下さい', (deliveryPassword) =>
      validPasswordLength(deliveryPassword),
    )
    .test(
      'numberRanges',
      '1-4の数字の組み合わせで入力して下さい',
      (deliveryPassword) => validPasswordRange(deliveryPassword),
    ),
  collectionPassword: Yup.number()
    .typeError('数字で入力して下さい')
    .required('必須項目です')
    .test('maxLength', '6桁以内で入力して下さい', (collectionPassword) =>
      validPasswordLength(collectionPassword),
    )
    .test(
      'numberRanges',
      '1-4の数字の組み合わせで入力して下さい',
      (collectionPassword) => validPasswordRange(collectionPassword),
    ),
  hubMacAddress: Yup.string()
    .required('必須項目です')
    .test(
      'maxLength',
      '入力文字数に誤りがあります。12文字で入力して下さい。',
      (hubMacAddress) =>
        hubMacAddress !== undefined && hubMacAddress.length === 12,
    )
    .test(
      'checkFormat',
      '入力に誤りがあります。正しいホームユニット機器番号を入力して下さい。',
      (hubMacAddress) =>
        hubMacAddress !== undefined &&
        /^(BCC342|0080F0|080023)[0-9A-F]{6}$/i.test(hubMacAddress),
    ),
});

const DeliveryBoxAddPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const appOperationDialog = useDialog();

  const deliveryBoxAuthPasswordDialog = useDialog();
  const deliveryBoxAuthPassword = useDeliveryBoxAuthPassword();
  const listDevice = useListDevice();

  const pollingDelay = 5000;
  const [isPolling, setIsPolling] = React.useState(false);
  const [pollingCount, setPollingCount] = React.useState(0);
  const createDevice = useCreateDevice();

  const formik = useFormik<Device>({
    initialValues: {
      collectionPassword: 0,
      deliveryPassword: 0,
      hubMacAddress: '',
      unlockMethodFirst: 'free',
      unlockMethodSecondOnward: 'number',
      yourPassword: 0,
      nickName: '宅配ボックス1',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      deliveryBoxAuthPasswordDialog.handleOpen();
      deliveryBoxAuthPassword.getAuthPassword(values.hubMacAddress);

      setPollingCount(300);
      setIsPolling(true);
    },
  });

  useEffect(() => {
    if (
      !listDevice.isCompleted &&
      !listDevice.isError &&
      !listDevice.isLoading
    ) {
      listDevice.getData();
    }
  }, [listDevice]);

  useEffect(() => {
    if (createDevice.isCompleted) {
      history.push(
        `/devices/delivery-boxes/${createDevice.data.deviceId}/address/add`,
      );
    }
  }, [createDevice.isCompleted, createDevice.data.deviceId, history]);

  useInterval(
    () => {
      formik.values.collectionPassword = Number(
        formik.values.collectionPassword,
      );
      formik.values.deliveryPassword = Number(formik.values.deliveryPassword);
      formik.values.yourPassword = Number(formik.values.yourPassword);
      createDevice.handleSubmit(formik.values);
      setPollingCount(pollingCount - pollingDelay / 1000);

      if (deliveryBoxAuthPassword.isError) {
        setIsPolling(false);
      }
      if (pollingCount <= 0) {
        setIsPolling(false);
        deliveryBoxAuthPasswordDialog.handleClose();
      }
    },
    isPolling ? pollingDelay : null,
  );

  const [isNext, setIsNext] = useState(false);
  const handlePrev = useCallback(() => {
    setIsNext(false);
  }, []);
  const handleNext = useCallback(async () => {
    const errorMessages = await formik.validateForm();

    if (errorMessages.hubMacAddress) {
      formik.setTouched({ hubMacAddress: true });
    } else {
      setIsNext(true);
    }
  }, [formik]);

  return (
    <GenericTemplate title="宅配ボックスの登録">
      <form onSubmit={formik.handleSubmit}>
        {!isNext ? (
          <>
            <Typography variant="h6" component="h3" className={classes.bold}>
              宅配ボックスのクラウド登録
            </Typography>
            <Box mb={3} component="p">
              ご利用の宅配ボックスを登録します。
            </Box>

            <Typography variant="body1" component="h4" className={classes.bold}>
              スマホアプリの作業
            </Typography>
            <List>
              <ListItem dense>
                <ListItemText
                  primary="1. アプリにスマート宅配ポストを登録する"
                  secondary={
                    <>
                      <Typography
                        variant="body2"
                        component="span"
                        className={classes.secondaryText}
                      >
                        1-1. アプリをインストールする
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className={classes.secondaryText}
                      >
                        1-2. ホームユニットとWiFiを接続する
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className={classes.secondaryText}
                      >
                        1-3. スマート宅配ポストを登録する
                      </Typography>
                    </>
                  }
                  secondaryTypographyProps={{
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
              <ListItem dense>
                <ListItemText primary="2. クラウド接続をONにする" />
              </ListItem>
              <ListItem dense>
                <ListItemText primary="3. ホームユニット機器番号をメモまたはコピーする" />
              </ListItem>
            </List>
            <Box mt={0} mb={3} component="p">
              上記の作業がまだの方は
              <Link
                color="primary"
                onClick={appOperationDialog.handleOpen}
                href="#"
              >
                こちら
              </Link>
              。
            </Box>

            <DeliveryBoxAddDialog
              open={appOperationDialog.open}
              onClose={appOperationDialog.handleClose}
            />

            <Typography variant="body1" component="h4" className={classes.bold}>
              機器番号の登録
            </Typography>
            <Box mt={1} mb={4}>
              <FormTextField
                defaultValue={formik.values.hubMacAddress}
                error={
                  !!formik.errors.hubMacAddress &&
                  !!formik.touched.hubMacAddress
                }
                helperText={formik.errors.hubMacAddress}
                label="ホームユニット機器番号"
                description="3. でコピーした「ホームユニット機器番号」を入力して下さい。"
                name="hubMacAddress"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="未設定"
              />
              <FormTextField
                defaultValue={formik.values.nickName}
                error={!!formik.errors.nickName && !!formik.touched.nickName}
                helperText={formik.errors.nickName}
                label="ニックネーム"
                description="この宅配ボックスを指定するときの名前をつけて下さい。"
                name="nickName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="未設定"
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  component={RouterLink}
                  to="/"
                  variant="contained"
                  fullWidth
                >
                  キャンセル
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleNext}
                  fullWidth
                  className={classes.bold}
                >
                  次へ
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="h6" component="h3" className={classes.bold}>
              宅配ボックスの解錠設定
            </Typography>

            <Box mt={2}>
              <MessageBox color="initial">
                配達時、スマート宅配ポストを解錠するときの番号の入力の有無を設定してください。
                <Typography variant="subtitle2" color="secondary">
                  ※すべて必須項目です。
                </Typography>
              </MessageBox>
            </Box>

            <Box mt={1} mb={4}>
              <FormTextField
                defaultValue=""
                error={
                  !!formik.errors.yourPassword && !!formik.touched.yourPassword
                }
                helperText={formik.errors.yourPassword}
                label="あなたの設定番号（数字の1〜4を使用して6桁以内）"
                name="yourPassword"
                description="集荷の荷物を入れるとき、配達された荷物を取り出すときに宅配ポストを解錠する番号"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="未設定"
                type="tel"
              />
              <FormTextField
                defaultValue=""
                error={
                  !!formik.errors.deliveryPassword &&
                  !!formik.touched.deliveryPassword
                }
                helperText={formik.errors.deliveryPassword}
                label="宅配業者の設定番号【配達用】（数字の1〜4を使用して6桁以内）"
                name="deliveryPassword"
                description="宅配時（宅配業者が荷物を入れるとき）、宅配ポストを解錠する番号"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="未設定"
                type="tel"
              />
              <FormTextField
                defaultValue=""
                error={
                  !!formik.errors.collectionPassword &&
                  !!formik.touched.collectionPassword
                }
                helperText={formik.errors.collectionPassword}
                label="宅配業者の設定番号【集荷用】（数字の1〜4を使用して6桁以内）"
                name="collectionPassword"
                description="宅配時（宅配業者が荷物を出すとき）、宅配ポストを解錠する番号"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="未設定"
                type="tel"
              />
              <FormRadioGroup
                defaultValue={formik.initialValues.unlockMethodFirst}
                items={[
                  {
                    description:
                      '「宅配業者の設定番号(配達用)」の入力をせずに解錠',
                    label: '設定番号なし',
                    value: 'free',
                  },
                  {
                    description:
                      '「宅配業者の設定番号(配達用)」の入力をして解錠',
                    label: '設定番号あり',
                    value: 'number',
                  },
                ]}
                label="ボックスに荷物が入っていない場合"
                name="unlockMethodFirst"
                onChange={formik.handleChange}
                fullWidth
              />
              <FormRadioGroup
                defaultValue={formik.initialValues.unlockMethodSecondOnward}
                label="ボックスに荷物が入っている場合"
                items={[
                  {
                    description:
                      '「宅配業者の設定番号(配達用)」の入力をして解錠',
                    label: '設定番号あり',
                    value: 'number',
                  },
                  {
                    description: '2回目以降の荷物は、配達されても解錠しない',
                    label: '解錠しない',
                    value: 'none',
                  },
                ]}
                name="unlockMethodSecondOnward"
                onChange={formik.handleChange}
                fullWidth
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button variant="contained" fullWidth onClick={handlePrev}>
                  戻る
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={classes.bold}
                  color="primary"
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  登録する
                </Button>
              </Grid>
            </Grid>
            {createDevice.isError && !deliveryBoxAuthPasswordDialog.open && (
              <Box mt={1}>
                <MessageBox color="secondary">
                  登録出来ませんでした。設定手順に従い、再度登録をしてください。
                  <br />
                  登録出来ない場合は、
                  <Link component={RouterLink} to={`/support#contact`}>
                    お問い合わせ
                  </Link>
                  までご連絡ください。
                </MessageBox>
              </Box>
            )}
          </>
        )}
      </form>

      <DeliveryBoxAuthPasswordDialog
        isError={deliveryBoxAuthPassword.isError}
        isLoading={deliveryBoxAuthPassword.isLoading}
        password={deliveryBoxAuthPassword.data.password}
        open={deliveryBoxAuthPasswordDialog.open}
        onCancel={() => {
          setIsPolling(false);
          setPollingCount(0);
          deliveryBoxAuthPasswordDialog.handleClose();
        }}
      />
    </GenericTemplate>
  );
};

export default DeliveryBoxAddPage;
